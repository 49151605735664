/**
*   button.scss
*   @description: Estilos para los botones
*/

/*---------------------- ESTILOS EN USO ------------------------*/

@mixin btn {
	cursor: pointer;
	font-family: $Mukta;
	font-size: $fontSmall;
	font-weight: $weightSemi;
	background-color: transparent;
	padding: $paddingMedium $paddingSemi;
	transition: all 200ms ease-in-out;
	&:active {
		transform: scale(0.95);
	}
}

@mixin hover-shine {
	text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.5);
	transition: all 0.4s ease 0s;
}

.btn {
	cursor: pointer;
	font-family: $Mukta;
	font-size: $fontSmall;
	font-weight: $weightSemi;
	background-color: transparent;
	padding: $paddingMedium $paddingSemi;
	transition: all 200ms ease-in-out;
	&:active {
		transform: scale(0.95);
	}
	// box-shadow: 1px 0 20px rgba(0, 0, 0, 0.05);
}

/*----------------------------------------------------------------*/

button {
	cursor: pointer;
	font-family: $Mukta;
	background-color: transparent;
	&:disabled {
		cursor: not-allowed;
	}
}

.btn-full {
	width: 100% !important;
}

.btn-primary {
	border-radius: 3px;
	background: var(--primary);
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	&:hover {
		background-color: rgba($primary, 0.8);
	}
}

.btn-secondary {
	background-color: var(--secondary);
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	&:hover {
		background-color: rgba($primary, 0.9);
	}
}

.btn-empty {
	background-color: transparent;
	border:0px solid var(--secondary);
	color:var(--secondary);
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	&:hover {
		// border:1.5px solid rgba($primary, 0.9);
		color:var(--primary);
		// background-color: rgba($primary, 0.9);
	}
}


.btn-small {
	padding: $paddingSmall $paddingNormal;
}

.btn-extrasmall {
	padding: $paddingSmall $paddingSmall;
}

.btn-mini {
	padding: 2px 7px;
	// font-size: 0.8rem;
}

.btn-medium {
	width: 50%;
}

.bnt-menu {
	border: 1px solid white;
	border-radius: 7px;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	-ms-border-radius: 7px;
	-o-border-radius: 7px;
}

.btn-table {
	&:hover {
		color: var(--primary);
	}
}
