/*LEFT SIDEBAR*/
.sidebar {
	background-color: var(--secondary);
	top: 0px;
	left: 0px;
	height: 100vh;
	width: 20%;
	position: sticky;
	z-index: 1;
	overflow-x: hidden;

	.logo {
		img {
			height: 40px;
		}
	}

	.container-name {
		img {
			height: 40px;
			border-radius: 20px;
			-webkit-border-radius: 20px;
			-moz-border-radius: 20px;
			-ms-border-radius: 20px;
			-o-border-radius: 20px;
		}

		color: var(--white);
		padding: 0 $paddingSmall;
		min-height: 50px;
		.column-name {
			padding-left: $paddingNormal;
		}
	}

	.panel {
		padding: 0 $paddingSemi;

		.item {
			padding: $paddingNormal 0;
			padding-left: $paddingSmall;
			border-bottom: 1px solid rgba(#838182, 0.3);
			transition: all 300ms;
		}

		.subitem {
			padding: $paddingNormal 0;
			padding-left: $paddingMedium;
			border-bottom: 1px solid rgba(#838182, 0.3);
			transition: all 300ms;
		}
		transition: all 300ms;
	}

	.panel:hover {
		div {
			color: var(--white);
		}
		// border-bottom: 2px solid var(--primary);
	}

	.panel {
		transition: color 2s;
		-webkit-transition: color 2s;
		-moz-transition: color 2s;
		-ms-transition: color 2s;
		-o-transition: color 2s;
	}

	.close {
		display: none;
	}

	.active {
		div {
			color: var(--white);
		}
		.item {
			border-bottom: 1px solid rgba($primary, 0.7);
		}

		.subitem {
			border-bottom: 1px solid rgba($primary, 0.7);
		}
		// background-color: #302e35;
		// border-bottom: 2px solid var(--primary);
	}
}

.submenu {
	position: fixed;
	top: 30px;
	left: calc(16.6%);
	background-color: #131415;
	width: 0px;
	overflow-x: hidden;
	transition: 300ms;
	z-index: 3;
	padding-top: 50px;
	transition: all 300ms;

	&.open {
		width: 220px;
		padding-left: 10px;
		z-index: 4;
		transition: all 300ms;
	}
	border-radius: 0px 5px 5px 0px;
	-webkit-border-radius: 0px 5px 5px 0px;
	-moz-border-radius: 0px 5px 5px 0px;
	-ms-border-radius: 0px 5px 5px 0px;
	-o-border-radius: 0px 5px 5px 0px;
}

/*    LARGE DEVICES    */

@media screen and (min-width: 1440px) {
}

/*    TABLETS DEVICES    */

@media screen and (max-width: 768px) {
}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
	.sidebar {
		position: fixed;
		padding-top: 70px;
		width: 0px;

		&.open {
			width: 50%;
		}
	}
}
