/*
 *  @version 1.4.0
 *  @author Trejocode - Sergio
 *  @description Base de colores
*/

:root {

    --primary:       #D4B467;
    --primary-alt:   #288091;
    --secondary:     #18191a;
    --secondary-alt: #516376;
    --success:       #27ae60;
    --warning:       #f39c12;
    --danger:        #c0392b;
    --info:          #258395;

    --black:     #000;
    --white:     #FFFFFF;
    --lightgray: #c8c9c7;
    --gray:      #838182;  
    --darkgray:  #4b4b4b;

    --darkBlue:  #263d54;
    --darkAqua:  #258395;
    --aqua:      #33c8a3;
    --lightBg:   #f6f7f9;

}

$primary :  #D4B467;
$secondary :  #18191a;

.color-primary {
    color: var(--primary);
}

.color-primary-alt {
    color: var(--primary-alt);
}

.color-secondary {
    color: var(--secondary);
}

.color-secondary-alt {
    color: var(--secondary-alt);
}

.color-success {
    color: var(--success);
}

.color-warning {
    color: var(--warning);
}

.color-danger {
    color: var(--danger);
}

.color-info {
    color: var(--info);
}

.color-gray {
    color: var(--gray);
}

.color-darkgray {
    color: var(--darkgray);
}

.color-black {
    color: var(--black);
}

.color-white {
    color: var(--white);
}



/* DARK THEME
   =============================
   Sobreescribir los valores para temas obscuros
*/

html[data-theme='dark'] {
 
    --primary:       #3E816D;
    --primary-alt:   #FFFFFF;
    --secondary:     #FFFFFF;
    --secondary-alt: #FFFFFF;
    --success:       #27ae60;
    --warning:       #f39c12;
    --danger:        #c0392b;
    --info:          #258395;

    --black:     #ebebeb;
    --white:     #0a0a0a;
    --gray:      #c2c2c2;  
    --darkgray:  #a0a0a0;

    body {
        background-color: #2c2c2c;
    }

    .color-white { color: var(--black); }
    

}