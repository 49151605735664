// COLORS
$overlay: rgba(0, 0, 0, 0.2);
$cancel: #ea4235;
$cancelLight: #eb5b51;
$hoverBtnClose: #6c757d86;
$background: #ffffff;

// ESTILOS GENERALES
@mixin button-action {
	width: 30%;
	padding: 6px 10px;
	margin-right: 8px;
	font-size: 1rem;
	font-weight: 500;
	color: white;
	transition: all 300ms ease-in-out;
	i {
		font-size: 1rem;
	}

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.4);
		// -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
		// -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
		// transition: all 0.4s ease 0s;
	}
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
}
// TAMAÑO MODAL
.dinamic-modal-micro {
	width: 25%;
}

.dinamic-modal-mini {
	width: 35%;
}

.dinamic-modal-small {
	width: 45%;
}

.dinamic-modal-normal {
	width: 50%;
}

.dinamic-modal-big {
	width: 57%;
}
.dinamic-modal-super-big {
	width: 80%;
}

// ESTILOS CUERPO MODAL

.dinamic-modal-container {
	outline: none;
	max-height: 100%;
	position: absolute;
	border-radius: 4px;
	background-color: var(--white);
	transition: max-height 1.5s ease-in-out;
	transform: translate(-50%, -50%);
	z-index: 5;

	.title {
		text-align: left;
	}

	.modal-container {
		width: 95%;
	}

	.dinamic-header {
		&:hover {
			cursor: pointer;
		}
		padding: 8px 0px 0px 0px;
		border-radius: 4px 4px 0 0;
		width: 100%;
		height: 40px;
		position: relative;
		border-bottom: 2px solid rgba(#c8c9c7, 0.2);
		.title {
			color: var(--darkgray);
			font-weight: 600;
		}

		.btn-modal-close {
			position: absolute;
			right: 2.3%;
			top: 19%;
			height: 20px;
			width: 20px;
			border-radius: 50%;
			color: var(--darkgray);
			font-size: 1.4rem;
			transition: background-color 400ms ease-in-out;

			&:hover {
				color: var(--primary);
			}
		}
	}

	.dinamic-body {
		height: auto;
		max-height: 770px;
		overflow-y: auto;
	}

	.column-image {
		padding: 10px;
		display: flex;
		flex-direction: column;
		position: relative;
		height: 120px;
		margin-left: 7px;

		.container-img {
			position: relative;
			width: 90%;
			// min-width: 328px;

			.img-duo-preview {
				min-height: 180px;
				max-height: 180px;
				width: 100%;
				object-fit: fill;
			}

			.image-area {
				opacity: 1;
				display: block;
				width: 100%;
				height: auto;
				transition: 0.5s ease;
				backface-visibility: hidden;
			}

			.middle-overlay {
				transition: 0.5s ease;
				opacity: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				background-color: #d4b367b0;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				text-align: center;
				.btn-upload-image {
					// min-width: 328px;
					width: 100%;
					height: 100%;
					// cursor: pointer;

					input[type="file"] {
						display: none;
					}
					i {
						font-size: 1rem;
						color: white;
						transition: color 300ms ease-in-out;
					}
				}
			}

			&:hover {
				.image-area {
					opacity: 0.3;
				}

				.middle-overlay {
					opacity: 1;
				}
			}
		}

		img {
			height: 100px;
			border-radius: 4px;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			-ms-border-radius: 4px;
			-o-border-radius: 4px;
		}
	}

	.img-trash {
		img {
			height: 80px;
		}
	}

	.dinamic-footer {
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.btn-action-success {
			@include button-action;
			background-color: $secondary;

			&:hover {
				background-color: $primary;
			}
		}

		.btn-action-cancel {
			@include button-action;
			color: var(--secondary);
			background-color: white;
			border: 0 solid var(--darkgray);
			border-radius: 3px;

			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			-ms-border-radius: 3px;
			-o-border-radius: 3px;

			&:hover {
				color: $cancelLight;
				// border: 1.5px solid $cancelLight;
				// background-color: $cancelLight;
			}
		}
	}
	.container-side-form {
		width: 90%;
	}

	/*Labels modal*/

	.label-normal {
		min-width: 25%;
		// min-width: 260px;

		p {
			font-size: $fontSmall;
			color: var(--darkgray);
		}
	}

	.label-medium {
		width: 41%;

		p {
			font-size: $fontSmall;
			color: var(--darkgray);
		}
	}

	.label-middle {
		width: 20%;
		padding-left: 20px;
		margin-right: 10px;
		p {
			font-size: $fontSmall;
			color: var(--darkgray);
		}
	}

	.column-small {
		width: 25%;
		// padding-left: 32px;
	}

	.column-middle {
		width: 33%;
		// padding-left: 32px;
	}
}

.overlay {
	top: 0;
	left: 0;
	z-index: 3;
	width: 100%;
	height: 100%;
	position: fixed;
	transition: height 1500ms ease-in-out;
	background-color: $overlay;
	::-webkit-scrollbar {
		width: 6px;
	}
	::-webkit-scrollbar-thumb {
		background-color: #cccccc;
	}
	display: flex;
	justify-content: center;
	align-items: center;
}

.show-dinamic-modal {
	opacity: 1;
	visibility: visible;
	transform: scale(1);
	transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.hide-dinamic-modal {
	opacity: 0;
	visibility: hidden;
	transform: scale(1.1);
	transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

// zoom image

.image-zoom-modal {
	display: flex;
	position: fixed;
	z-index: 100;
	/*padding-top: 10px; */
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	/*background-color: rgb(0,0,0); */
	background-color: rgba(119, 117, 117, 0.582);
}

.hide-zoom-modal {
	opacity: 0;
	visibility: hidden;
	transform: scale(1.1);
	transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.show-zoom-modal {
	opacity: 1;
	visibility: visible;
	transform: scale(1);
	transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
	z-index: 1;
}

.modal-content-zoom {
	background-color: #efefde00;
	margin: 85px;
	border-radius: 5px;
	padding-right: 20px;
	padding-left: 20px;
	width: 40%;
	max-height: 80%;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

.img-zoom {
	max-height: 60vh;
}

#modal-close-zoom {
	background-color: #efefde00;
	color: white;
	font-size: $fontTriple;
	font-weight: 900;
	cursor: pointer;
}

/*    LARGE DEVICES    */

@media screen and (min-width: 1440px) {
}

/*    TABLETS DEVICES    */

@media screen and (max-width: 768px) {
	.dinamic-modal-micro {
		width: 85%;
	}
	.dinamic-modal-mini {
		width: 85%;
	}
	.dinamic-modal-small {
		width: 85%;
	}

	.dinamic-modal-normal {
		width: 85%;
	}

	.dinamic-modal-big {
		width: 85%;
		max-height: 85%;
	}
}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
	.dinamic-modal-container {
		.container-side-form {
			width: 100%;
		}
	}
}
