.images-upload-files {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	transition: all 0.5 ease-in-out;

	.upload-container {
		// background: #fff;
		box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
		position: relative;
		border-radius: 10px;
		padding: 20px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: 10px auto;
		transition: all 0.3s ease-in;

		.uploadIcon {
			width: 50px;
			height: 50px;
		}

		input {
			opacity: 0;
			position: absolute;
			z-index: -1;
		}
		
		p {
			font-size: 12px;
			margin: 8px 0 4px;
		}

		.errorsContainer {
			max-width: 300px;
			font-size: 12px;
			color: red;
			text-align: left;
		}

		.chose-file-button {
			padding: 6px 23px;
			background: #3f4257;
			border-radius: 30px;
			color: white;
			font-weight: 300;
			font-size: 14px;
			margin: 10px 0;
			transition: all 0.2s ease-in;
			cursor: pointer;
			outline: none;
			border: none;

			&:hover {
				background: #545972;
			}
		}

		.pictures-row {
			display: flex;
			justify-content: center;
			width: 100%;
		}

		.uploadPictureContainer {
			width: 125px;
			margin: 8px;
			padding: 0px;
			background: #edf2f6;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 140px;
			box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
			border: 1px solid #d0dbe4;
			position: relative;

			.deleteImage {
				position: absolute;
				top: -9px;
				right: -9px;
				color: #fff;
				background: #ff4081;
				border-radius: 50%;
				text-align: center;
				cursor: pointer;
				font-size: 26px;
				font-weight: bold;
				line-height: 30px;
				width: 30px;
				height: 30px;
			}

			.uploadPicture {
				width: 100%;
				cursor: pointer;
			}
		}
	}

	.flipMove {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
}