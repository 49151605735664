.dashboard {
	// .chartjs-render-monitor {
	// 	// background-color: white;
	// 	// padding: 16px;
	// }

	.card {
		border-top: 5px solid var(--primary);
		padding: 5px 5px 5px 5px;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
		box-shadow: 1px 0 20px rgba(0, 0, 0, 0.05);
		padding: 0 $paddingMedium;
		height: 800px;
		overflow-y: auto;
	}

	.row-solicitud {
		// width: 100%;
		min-height: 70px;
		.img-solicitud {
			img {
				height: 70px;
			}
		}
		border-bottom: 2px solid rgba($secondary, 0.1);
	}

	.card-stats {
		border-top: 5px solid var(--primary);
		padding: 5px 5px 5px 5px;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
		box-shadow: 1px 0 20px rgba(0, 0, 0, 0.05);
		padding: 0 $paddingMedium;
		height: 90px;
	}
}
